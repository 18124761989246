<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row row-cols-sm-2 row-cols-md-2 mb-4">
      <div class="col">
        <router-link to="/parameter/pemetaan-regional-area"
          ><a-button type="link" style="text-align:middle;" block>Regional</a-button></router-link
        >
      </div>
      <div class="col">
        <router-link to="/parameter/lokasi-area"
          ><a-button type="primary" style="text-align:middle;" block
            >Lokasi Area</a-button
          ></router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-select
          :options="[
            { value: null, label: 'Semua' },
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          class="mr-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="active"
        ></a-select>
        <a-input-search v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="m-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-2"
          title="Tambah Area"
          type="primary"
          @click="
            () => {
              formState = {}
              formState.status_approval = 0
              formState.type = 1
              modal1Visible = true
              formState.title_state = 'Tambah Area'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="table-responsive ">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :meta="meta"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #customTitle>
          <span>
            No
          </span>
        </template>

        <template #id="{ id }">
          <a href="javascript: void(0);">{{ id }}</a>
        </template>
        <template #status="{ record }">
          <a-tag v-if="record.active" color="#108ee9">{{ record.status }}</a-tag>
          <a-tag v-else color="grey">{{ record.status }}</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Area">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="lihatRole(record.id)"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Area">
              <a
                href="javascript: void(0);"
                class="btn  btn-sm btn-light"
                @click="editRole(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus Area">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="postDelete(record.id)"
              >
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <a-modal
      v-model:visible="modal1Visible"
      :title="formState.title_state"
      width="700px"
      :destroy-on-close="true"
      :mask-closable="false"
      @ok="modal1Visible = false"
      ref="modalRef"
    >
      <pre v-if="!true">{{ formState }}</pre>
      <template #footer>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        ></template
      >
      <a-form
        ref="formRef"
        v-if="modal1Visible"
        :rules="rules"
        :model="formState"
        :wrapper-col="{ span: 14 }"
        class="myform"
      >
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Regional"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              :max-tag-count="3"
              required
              allow-clear
              name="parent_id"
            >
              <filter-regional
                :mode="null"
                style="width: 100%"
                v-model:value="formState.parent_id"
                label="fullname"
                :disabled="formState.readOnly"
              ></filter-regional>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Nama Area"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              has-feedback
              name="name"
            >
              <a-input
                width="100%"
                v-model:value="formState.name"
                :disabled="formState.readOnly"
                required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Kabupaten/Kota"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              name="wilayah_id"
            >
              <filter-kabupaten
                style="width: 100%"
                v-model:value="formState.wilayah_id"
                label="fullname"
                :disabled="formState.readOnly"
                :join-options="formState.wilayah"
                @select="kabupatenSelect"
                required
              ></filter-kabupaten>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Status"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              name="active"
            >
              <a-radio-group
                :disabled="formState.readOnly"
                v-model:value="formState.active"
                required
              >
                <a-radio :value="true">Aktif</a-radio>
                <a-radio :value="false">Tidak Aktif</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  reactive,
  provide,
  toRefs,
  nextTick,
  createVNode,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import FilterKabupaten from '@/components/filter/FilterKabupaten'
import FilterRegional from '@/components/filter/FilterRegional'
// import columnss from './columns'

import qs from 'qs'
import moment from 'moment'

const acolumns = [
  {
    dataIndex: 'id',
    align: 'center',
    slots: {
      title: 'customTitle',
      customRender: 'no',
    },
  },
  {
    title: 'Nama Area',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: 'Status',
    slots: { customRender: 'status' },
    align: 'center',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]

export default defineComponent({
  name: 'MFilter',
  components: {
    FilterKabupaten,
    FilterRegional,
  },
  setup() {
    const loading = ref(false)
    const modal1Visible = ref(false)
    const formState = ref({})
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const ccolumns = ref([])
    //const data = ref({})
    const current1 = ref(1)

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({
      totalCount: 0,
      perPage: 10,
      currentPage: 1,
    })
    const page = ref(1)

    const data = ref([])
    const q = ref('')
    const kabupaten = ref([])
    const active = ref(null)
    const isPosting = ref(false)
    const value1 = ref(true)
    const value2 = ref(false)
    const errorMessage = ref(null)
    const modalRef = ref('')

    const formStates = reactive({
      name: undefined,
      code: undefined,
    })

    const formRef = ref('')

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    columns.value = acolumns

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/areas', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Lokasi-area_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: meta.value.currentPage,
        'per-page': meta.value.perPage,
        q: q.value,
        active: active.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/areas', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const lihatRole = id => {
      apiClient
        .get(`/api/areas/${id}?expand=wilayah`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Lihat Area'
          formState.value.readOnly = true
          if (formState.value.wilayah) {
            formState.value.wilayah_id = formState.value.wilayah.map(i => i.id)
          }
        })
        .catch(error => console.error(error))
    }
    const editRole = id => {
      apiClient
        .get(`/api/areas/${id}?expand=wilayah`)
        .then(response => {
          const data = response.data
          formState.value = data
          formState.value.title_state = 'Edit Area'
          formState.value.readOnly = false
          if (formState.value.wilayah) {
            formState.value.wilayah_id = formState.value.wilayah.map(i => i.id)
          }
          formState.value.state = 'edit'
          modal1Visible.value = true
        })
        .catch(error => console.error(error))
    }

    const submit = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Tambah',
          content: 'Apakah anda ingin melanjutkan tambah?',

          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            apiClient
              .post('/api/areas', formState.value)
              .then(({ data }) => {
                apiClient
                  .post('/api/areas/' + data.id + '/assign', {
                    wilayah_id: formState.value.wilayah_id,
                  })
                  .then(({ data }) => {
                    modal1Visible.value = false
                    isPosting.value = false
                    fetchData()
                    message.success('Berhasil disimpan')
                  })
                  .catch(e => {
                    console.error('assign kabupaten', { e })
                    message.error('Gagal Menyimpan Kabupaten!')
                  })
              })
              .catch(e => message.error('Gagal menyimpan, silahkan isi baris sesuai nama'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit',
          content: 'Apakah anda ingin melanjutkan Edit?',
          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            // TODO: validasi
            apiClient
              .post('/api/areas/' + formState.value.id + '?_method=PUT', formState.value)
              .then(({ data }) => {
                apiClient
                  .post('/api/areas/' + data.id + '/assign', {
                    wilayah_id: formState.value.wilayah_id,
                  })
                  .then(({ data }) => {
                    modal1Visible.value = false
                    isPosting.value = false
                    fetchData()
                    message.success('Berhasil disimpan')
                  })
                  .catch(e => {
                    console.error('assign kabupaten', { e })
                    message.error('Gagal Menyimpan Kabupaten!')
                  })
              })
              .catch(e => message.error('Gagal menyimpan, silahkan isi baris sesuai nama'))
              .finally(e => {
                isPosting.value = false
              })
          },
          onCancel() {},
        })
      }
    }

    const postDelete = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus',
        content: 'Apakah anda ingin Hapus lokasi ?',
        onOk() {
          apiClient
            .delete(`/api/areas/${id}`)
            .then(response => {
              const data = response.data
              formState.value = data
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal mengupdate!'))
        },
        onCancel() {},
      })
    }

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const search = () => {
      fetchData()
    }

    onMounted(() => {
      fetchData()
    })

    const handleTableChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
      fetchData()
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }
    const visible = ref(false)

    const validate = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState))
        })
        .catch(error => {
          console.error('error', error)
        })
    }

    const rules = {
      parent_id: {
        required: true,
        message: 'Regional belum dipilih!',
        type: 'number',
      },
      name: {
        required: true,
        message: 'Masukan Nama Area',
      },
      active: {
        required: true,
        message: 'Status belum dipilih!',
        type: 'boolean',
      },
      wilayah_id: {
        required: true,
        message: 'Kabupaten/Kota belum dipilih!',
        type: 'array',
      },
    }

    watch(modal1Visible, (newValue, oldValue) => {
      if (newValue === false) {
        formState.value = {}
      }
    })

    const kabupatenSelect = (select, option, { name, area_name, area_id }, preValue) => {
      if (area_name === null) return

      Modal.confirm({
        title: 'Confirm',
        zIndex: 99999,
        icon: createVNode(ExclamationCircleOutlined),
        content: `${name} sudah diatur ke ${area_name}`,
        okText: 'Lanjutkan',
        cancelText: 'Batalkan',
        onCancel() {
          formState.value.wilayah_id = preValue
        },
      })
    }

    return {
      modal1Visible,
      loading,
      visible,
      handleOk,
      formState,
      setModal1Visible,
      data,
      columns,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      searchText,
      searchInput,
      fetchXlsx,
      q,
      search,
      state,
      ...toRefs(state),
      fetchData,
      handleTableChange,
      // modalRef,
      handleReset,
      handleSearch,
      onSelectChange,
      active,
      submit,
      isPosting,
      lihatRole,
      editRole,
      postDelete,
      value1,
      value2,
      errorMessage,
      validate,
      rules,
      formRef,
      formStates,
      ccolumns,
      kabupatenSelect,
    }
  },
})
</script>

<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector),
.myform :deep(.ant-radio-disabled + span) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
.hiden {
  visibility: visible;
}
</style>
