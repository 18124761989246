<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Kabupaten / Kota"
    allow-clear
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    v-bind="$attrs"
    @select="select"
    :show-arrow="true"
  >
    <a-select-option v-for="d in dataRegional" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, merge, keyBy, values } from 'lodash'

export default {
  inheritAttrs: false,
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    rawOption: {
      default: () => {},
      type: Object,
    },
    joinOptions: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value', 'update:rawOption', 'select'],
  setup(props, { emit }) {
    const dataRegional = ref([])
    const findText = ref(null)
    const loading = ref(false)
    const joinOptions = toRefs(props).joinOptions

    const fetchDataRegional = (q = null) => {
      loading.value = true
      apiClient
        .get('/api/filter/wilayah/2', {
          params: { q },
        })
        .then(response => {
          let sorted = response.data
          if (joinOptions.value.length > 0) {
            const merged = merge(keyBy(sorted, 'id'), keyBy(joinOptions.value, 'id'))
            sorted = values(merged)
          }
          dataRegional.value = sorted.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        })
        .finally(() => {
          loading.value = false
        })
    }
    onMounted(() => {
      let sorted = []
      if (joinOptions.value.length > 0) {
        const merged = merge(keyBy(sorted, 'id'), keyBy(joinOptions.value, 'id'))
        sorted = values(merged)
        dataRegional.value = sorted.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        )
      }

      fetchDataRegional()
    })

    const onSearch = debounce(value => {
      loading.value = true
      findText.value = value
      fetchDataRegional(value)
    }, 300)

    const select = (value, option) => {
      if (findText.value !== null) {
        // fetchDataRegional()
        findText.value = null
      }
      // console.log({ value, option, theValue: props.value })
      const rawOption = dataRegional.value.find(d => d.id === value)
      emit('update:rawOption', rawOption)
      emit('select', value, option, rawOption, props.value)
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const dropdownVisibleChange = value => {
      if (findText.value !== null) {
        fetchDataRegional()
        findText.value = null
      }
    }
    return {
      dataRegional,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
